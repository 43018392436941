import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { LinkedinLogo } from 'phosphor-react';
import React from 'react';
import { IEmployee } from '../models/employee';

import './employee.scss';

interface EmployeeProps {
  employee: IEmployee;
}

export const Employee: React.FC<EmployeeProps> = ({ employee }) => {
  const image = getImage(employee.photo.localFile)!;

  return (
    <div className="employee">
      <div className="employee__picture">
        <GatsbyImage
          image={image}
          alt={employee.photo.alternativeText}
          className="employee__picture__img"
        />
      </div>
      <div className="employee__name">{employee.name}</div>
      <div className="employee__position">{employee.role}</div>
      <div className="employee__description">{employee.description}</div>
      <div>
        <a
          className="employee__linked-in-link"
          href={employee.linkedInLink}
          target="_blank"
        >
          <LinkedinLogo />
        </a>
      </div>
    </div>
  );
};
