import React from 'react';

interface StrapiSvgImageProps {
  src: string;
  alt: string;
  className: string;
}

export const StrapiSvgImage: React.FC<StrapiSvgImageProps> = ({
  src,
  alt,
  className,
}) => {
  const newSrc = process.env.GATSBY_STRAPI_URL + src;
  return <img className={className} src={newSrc} alt={alt} />;
};
