import { graphql, useStaticQuery } from 'gatsby';
import { ArrowsOut, MapPinLine } from 'phosphor-react';
import React, { createRef, useEffect, useState } from 'react';
import { ContactForm } from '../components/contact-form';
import { Employee } from '../components/employee';
import { KeyValue } from '../components/key-value';
import { Layout } from '../components/layout';
import { Review } from '../components/review';
import { IEmployee } from '../models/employee';
import { IKeyValue } from '../models/key-value';
import { IReview } from '../models/review';
import { ISeo } from '../models/seo';
import bgBase from '../assets/images/uppy-bg-base.jpg';
import bgOverlay from '../assets/images/uppy-bg-overlay.jpg';
import reviewsHumans from '../assets/images/reviews-humans.svg';

import './index.scss';
import { IImage } from '../models/image';
import { StrapiSvgImage } from '../components/strapi-svg-image';
import axios from 'axios';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

interface IndexPageData {
  strapiHomepage: {
    seo: ISeo;
    heroBanner: {
      title: string;
      subtitle: string;
      mainButtonText: string;
      mainButtonTooltip: string;
      pinImage: IImage;
    };
    sectionValue: {
      title: string;
      subtitle: string;
      values: IKeyValue[];
    };
    sectionReview: {
      title: string;
      subtitle: string;
      reviews: IReview[];
    };
    sectionMap: {
      title: string;
      mapCover: IImage;
      phonesImage: IImage;
      subtitle: string;
      buttonText: string;
    };
    sectionEmployee: {
      title: string;
      subtitle: string;
      employees: IEmployee[];
    };
    sectionContact: {
      title: string;
      subtitle: string;
    };
  };
}

const IndexPage: React.FC = () => {
  const data: IndexPageData = useStaticQuery(query);
  const home = data.strapiHomepage;

  const baseBgImage = createRef<HTMLImageElement>();
  const bgWrapperRef = createRef<HTMLDivElement>();

  const [scaleRatio, setScaleRatio] = useState<number>();
  const [surelevationsLength, setSurelevationsLength] = useState<number>(200);
  const [mainButtonTooltip, setMainButtonTooltip] = useState<string>(
    home.heroBanner.mainButtonTooltip
  );

  const screenSizeChange = () => {
    const image = baseBgImage.current;
    if (!image) {
      return;
    }

    const imageWidth = image.clientWidth;
    const imageHeight = image.clientHeight;
    const documentWidth = bgWrapperRef.current!.clientWidth;
    const documentHeight = bgWrapperRef.current!.clientHeight;
    const widthRatio = documentWidth / imageWidth;
    const heightRatio = documentHeight / imageHeight;
    const scaleRatio = Math.max(widthRatio, heightRatio);
    const scaledImageHeight = imageHeight * widthRatio;
    document.body.style.setProperty(
      '--bg-wrapper-height',
      scaledImageHeight + 'px'
    );
    setScaleRatio(scaleRatio);
  };

  useEffect(() => {
    axios
      .get(process.env.GATSBY_API_URL + '/surelevations/length')
      .then((result) => {
        setSurelevationsLength(result.data.length);
      });

    window.addEventListener('resize', screenSizeChange);
    baseBgImage.current!.onload = screenSizeChange;
    screenSizeChange();
  });

  useEffect(() => {
    setMainButtonTooltip(
      home.heroBanner.mainButtonTooltip.replace(
        '{{number}}',
        surelevationsLength + ''
      )
    );
  }, [surelevationsLength]);

  const mapCoverImage = getImage(home.sectionMap.mapCover.localFile)!;
  const phonesImage = getImage(home.sectionMap.phonesImage.localFile)!;

  return (
    <Layout seo={home.seo}>
      <div className="homepage">
        <div className="homepage__hero">
          <div className="homepage__hero__blur"></div>
          <div className="homepage__hero__content">
            <div className="homepage__hero__content-in">
              <h1 className="homepage__hero__title">{home.heroBanner.title}</h1>
              <div className="homepage__hero__sub">
                {home.heroBanner.subtitle}
              </div>
              <div className="homepage__hero__actions">
                <div>
                  <a
                    href="/la-carte"
                    className="uppy-button uppy-button--primary homepage__hero__main-button"
                  >
                    <MapPinLine className="uppy-button__icon" />
                    {home.heroBanner.mainButtonText}
                  </a>
                </div>
                <div className="homepage__hero__main-button-tooltip">
                  {mainButtonTooltip}
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              'homepage__hero__background-wrapper' +
              (scaleRatio ? ' homepage__hero__background-wrapper--visible' : '')
            }
            ref={bgWrapperRef}
          >
            <div className="homepage__hero__background">
              <div
                className="homepage__hero__background__in"
                style={{ transform: `scale(${scaleRatio})` }}
              >
                <img
                  src={bgBase}
                  alt="Surrélevation"
                  className="homepage__hero__background__main"
                  ref={baseBgImage}
                />
                <div className="homepage__hero__background__overlay-container">
                  <img
                    src={bgOverlay}
                    alt="Surrélevation"
                    className="homepage__hero__background__overlay"
                  />
                  <div className="homepage__hero__background__pin-container">
                    <StrapiSvgImage
                      src={home.heroBanner.pinImage.url}
                      alt={home.heroBanner.pinImage.alternativeText}
                      className="homepage__hero__background__pin"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="homepage__content">
          <div className="homepage__section">
            <div className="homepage__section__head">
              <h2 className="homepage__section__title">
                {home.sectionValue.title}
              </h2>
              <div className="homepage__section__sub">
                {home.sectionValue.subtitle}
              </div>
            </div>
            <div className="homepage__key-values">
              {home.sectionValue.values.map((value, i) => (
                <KeyValue value={value} key={i} />
              ))}
            </div>
          </div>

          <div className="homepage__section">
            <div className="homepage__section__head">
              <div id="carte" className="scroll-anchor"></div>
              <h2 className="homepage__section__title">
                {home.sectionMap.title}
              </h2>
              <div className="homepage__section__sub">
                {home.sectionMap.subtitle}
              </div>
            </div>

            <div className="homepage__map__images">
              <a href="/la-carte">
                <GatsbyImage
                  image={mapCoverImage}
                  alt={home.sectionMap.mapCover.alternativeText}
                  className="homepage__map__images__cover"
                />
              </a>
              <div className="homepage__map__images__phones">
                <GatsbyImage
                  image={phonesImage}
                  alt={home.sectionMap.phonesImage.alternativeText}
                />
              </div>
            </div>

            <a href="/la-carte" className="uppy-button uppy-button--primary">
              <ArrowsOut className="uppy-button__icon" />
              {home.sectionMap.buttonText}
            </a>
          </div>

          <div className="homepage__section">
            <div className="homepage__section__head">
              <div id="avis" className="scroll-anchor"></div>
              <h2 className="homepage__section__title">
                {home.sectionReview.title}
              </h2>
              <div className="homepage__section__sub">
                {home.sectionReview.subtitle}
              </div>
            </div>

            <div className="homepage__reviews">
              <div className="homepage__reviews__humans">
                <img src={reviewsHumans} alt="" />
              </div>
              <div className="homepage__reviews__list">
                {home.sectionReview.reviews.map((review, i) => (
                  <Review key={i} review={review} />
                ))}
              </div>
            </div>
          </div>

          <div className="homepage__section">
            <div className="homepage__section__head">
              <div id="equipe" className="scroll-anchor"></div>
              <h2 className="homepage__section__title">
                {home.sectionEmployee.title}
              </h2>
              <div className="homepage__section__sub">
                {home.sectionEmployee.subtitle}
              </div>
            </div>

            <div className="homepage__employees">
              <div className="homepage__employees__list">
                {home.sectionEmployee.employees.map((employee, i) => (
                  <Employee employee={employee} key={i} />
                ))}
              </div>
            </div>
          </div>

          <div className="homepage__section">
            <div id="contact" className="scroll-anchor"></div>
            <div className="homepage__section__head">
              <h2 className="homepage__section__title">
                {home.sectionContact.title}
              </h2>
              <div className="homepage__section__sub">
                {home.sectionContact.subtitle}
              </div>
            </div>

            <div className="homepage__contact">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      seo {
        metaTitle
        metaDescription
        shareImage {
          url
        }
      }
      heroBanner {
        title
        subtitle
        mainButtonTooltip
        mainButtonText
        pinImage {
          alternativeText
          url
        }
      }
      sectionValue {
        title
        subtitle
        values {
          main
          title
          icon {
            alternativeText
            url
          }
          content
        }
      }
      sectionMap {
        title
        mapCover {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 800)
            }
          }
        }
        phonesImage {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 520)
            }
          }
        }
        subtitle
        buttonText
      }
      sectionReview {
        title
        subtitle
        reviews {
          content
          reviewer
        }
      }
      sectionEmployee {
        title
        subtitle
        employees {
          linkedInLink
          name
          role
          description
          photo {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 112, height: 112)
              }
            }
          }
        }
      }
      sectionContact {
        title
        subtitle
      }
    }
  }
`;

export default IndexPage;
