import axios from 'axios';
import { graphql, useStaticQuery } from 'gatsby';
import { CheckCircle } from 'phosphor-react';
import React, { useState } from 'react';
import logo from '../assets/images/logo.svg';

import './contact-form.scss';

type InputType = string | number | readonly string[] | undefined;

interface IFormValues {
  firstName: InputType;
  lastName: InputType;
  phone: InputType;
  email: InputType;
  reason: InputType;
  message: InputType;
}

interface ContactFormData {
  strapiContactForm: {
    reasons: string;
    submitText: string;
  };
}

export const ContactForm: React.FC = () => {
  const data: ContactFormData = useStaticQuery(query);
  const contactForm = data.strapiContactForm;

  const reasons = contactForm.reasons.split('\n').filter((str) => !!str);

  const [formValues, setFormValues] = useState<IFormValues>({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    reason: undefined,
    message: '',
  });

  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    axios.post(process.env.GATSBY_API_URL + '/contact', formValues).then(() => {
      setSubmitSuccess(true);
    });
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormValues({ ...formValues, firstName: event.target.value });
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, lastName: event.target.value });
  };
  const handleEmailNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormValues({ ...formValues, email: event.target.value });
  };
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, phone: event.target.value });
  };
  const handleReasonChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFormValues({ ...formValues, reason: event.target.value });
  };
  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFormValues({ ...formValues, message: event.target.value });
  };

  return (
    <div className="contact-form">
      <div className="contact-form__logo">
        <img src={logo} alt="Uppy" />
      </div>
      <div className="contact-form__desc">
        Pour nous contacter, remplissez le formulaire ci-dessous :
      </div>
      <form onSubmit={handleSubmit}>
        <div className="uppy-field">
          <input
            placeholder="Votre prénom *"
            type="text"
            className="uppy-input"
            name="given-name"
            required
            value={formValues.firstName}
            onChange={handleFirstNameChange}
          />
        </div>
        <div className="uppy-field">
          <input
            placeholder="Votre nom *"
            type="text"
            className="uppy-input"
            name="family-name"
            required
            value={formValues.lastName}
            onChange={handleLastNameChange}
          />
        </div>
        <div className="uppy-field">
          <input
            placeholder="Votre mail *"
            type="email"
            className="uppy-input"
            name="email"
            required
            value={formValues.email}
            onChange={handleEmailNameChange}
          />
        </div>
        <div className="uppy-field">
          <input
            placeholder="Votre téléphone"
            type="phone"
            className="uppy-input"
            name="tel"
            value={formValues.phone}
            onChange={handlePhoneChange}
          />
        </div>
        <div className="uppy-field">
          <select
            className="uppy-input"
            name="reason"
            value={formValues.reason}
            onChange={handleReasonChange}
          >
            <option value={undefined}>Sélectionnez une raison...</option>
            {reasons.map((reason, i) => (
              <option value={reason} key={i}>
                {reason}
              </option>
            ))}
          </select>
        </div>
        <div className="uppy-field">
          <textarea
            required
            placeholder="Décrivez-nous votre projet *"
            className="uppy-input"
            name="message"
            value={formValues.message}
            onChange={handleMessageChange}
          ></textarea>
        </div>
        <button className="contact-form__submit uppy-button uppy-button--primary">
          {contactForm.submitText}
        </button>
      </form>
      {submitSuccess && (
        <div className="contact-form__success">
          <div className="contact-form__success__icon">
            <CheckCircle />
          </div>
          <div className="contact-form__success__text">
            Nous avons bien reçu votre message, nous reviendrons vers vous très
            vite
          </div>
        </div>
      )}
    </div>
  );
};

const query = graphql`
  query {
    strapiContactForm {
      reasons
      submitText
    }
  }
`;
