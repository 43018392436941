import React from 'react';
import { IReview } from '../models/review';

import './review.scss';

interface ReviewProps {
  review: IReview;
}

export const Review: React.FC<ReviewProps> = ({ review }) => (
  <div className="review">
    <div className="review__quote">“</div>
    <div className="review__text">{review.content}</div>
    <div className="review__name">{review.reviewer}</div>
  </div>
);
