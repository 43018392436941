import React from 'react';
import { IKeyValue } from '../models/key-value';

import './key-value.scss';
import { StrapiSvgImage } from './strapi-svg-image';

interface KeyValueProps {
  value: IKeyValue;
}

export const KeyValue: React.FC<KeyValueProps> = ({ value }) => (
  <div className={'key-value' + (value.main ? ' key-value--main' : '')}>
    <div className="key-value__image">
      <StrapiSvgImage src={value.icon.url} alt={value.icon.alternativeText} />
    </div>
    <div className="key-value__title">{value.title}</div>
    <div className="key-value__content">{value.content}</div>
  </div>
);
